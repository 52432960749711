import device from "src/constants/media";
import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 24px;
  margin-inline: auto;
  max-width: 1096px;
  padding-inline: 24px;
  text-align: center;

  @media ${device.laptopS} {
    margin-top: 40px;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px 40px;
  place-content: space-evenly;

  > img {
    max-height: 96px;
    max-width: 144px;
  }
`;
