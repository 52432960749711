import Image from "next/image";
import React from "react";
import { USED_BY_BLOCK } from "src/constants/dynamic";
// hooks
import { useAppSelector } from "src/hooks";
// redux
import { dynamicStateFieldSelector } from "src/redux/slices";
import { colors } from "src/theme";
import { Title } from "src/theme/typography";
// types
import { IDynamicUsedBy } from "src/types/dynamic/clinics/usedBy";
// theme
import { Section, LogosContainer } from "./Customers.styles";

const Customers = () => {
  const data = useAppSelector(dynamicStateFieldSelector<IDynamicUsedBy>(USED_BY_BLOCK));

  return (
    <Section>
      <Title textColor={colors.white}>{data?.title}</Title>
      <LogosContainer>
        {data.logos.map(({ id, image }) => (
          // <Image key={id} src={image.url} width={144} height={96} objectFit="scale-down" />
          <img key={id} src={image.url} />
        ))}
      </LogosContainer>
    </Section>
  );
};

export default Customers;
